<template>
	<el-dialog top="1vh" title="货车详情" width="1050px" :visible.sync="is_show_in_page">
		<el-form class="form_ordinary" label-width="160px">

      <div class="big_tit" style="margin-top: 0px">基本数据</div>
      <div class="tab1_inner">
        <el-form-item class="el_form_item" label="所有人" style="width: 45%">
            <el-input class="el_inner_width" v-model="form.truck_owner_name" disabled></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="车辆识别代号" style="width: 45%">
            <el-input class="el_inner_width" v-model="form.truck_vin_code" disabled></el-input>
        </el-form-item>
      </div>
      <div class="tab1_inner">
        <el-form-item class="el_form_item" label="使用性质" style="width: 45%">
            <el-input class="el_inner_width" v-model="form.truck_used" disabled></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="行驶证发证机关" style="width: 45%">
            <el-input class="el_inner_width" v-model="form.dl_ca" disabled></el-input>
        </el-form-item>
      </div>
      <div class="tab1_inner">
        <el-form-item class="el_form_item" label="行驶证注册日期" style="width: 45%">
          <el-date-picker style="width: 265px" disabled v-model="time.dl_reg_date" type="date" placeholder="选择日期" value-format="timestamp"></el-date-picker>
        </el-form-item>
        <el-form-item class="el_form_item" label="行驶证发证日期" style="width: 45%">
           <el-date-picker style="width: 265px" disabled v-model="time.dl_award_date" type="date" placeholder="选择日期" value-format="timestamp"></el-date-picker>
        </el-form-item>
      </div>
      <div class="tab1_inner">
        <el-form-item class="el_form_item" label="道路运输证号" style="width: 45%">
            <el-input class="el_inner_width" disabled v-model="form.tl_code"></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="道路运输经营许可号" style="width: 45%">
            <el-input class="el_inner_width" disabled v-model="form.tl_cert_code"></el-input>
        </el-form-item>
      </div>
      <div class="tab1_inner">
        <el-form-item class="el_form_item" label="车牌号" style="width: 45%">
          <el-input
            class="el_inner_width"
            v-model="form.truck_plate_num"
			disabled
          ></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="车牌类型" style="width: 45%" >
          <el-select class="el_inner_width" v-model="form.truck_plate_type" disabled>
            <el-option label="未选择" value="0"></el-option>
            <el-option label="黄牌" value="1"></el-option>
            <el-option label="蓝牌" value="2"></el-option>
            <el-option label="绿牌" value="3"></el-option>
          </el-select>
        </el-form-item>
      </div>

      <div class="tab1_inner">
        <el-form-item class="el_form_item" label="额定载重(吨)" style="width: 45%" >
          <el-input class="el_inner_width" v-model="form.truck_weight" disabled></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="额载体积(方)" style="width: 45%">
          <el-input class="el_inner_width" v-model="form.truck_volume" disabled></el-input>
        </el-form-item>
      </div>
      <div class="tab1_inner">
        <el-form-item class="el_form_item" label="能源类型" style="width: 45%" >
          <el-select class="el_inner_width" v-model="form.en_type" disabled>
            <el-option label="汽油" value="A"></el-option>
            <el-option label="柴油" value="B"></el-option>
            <el-option label="电" value="C"></el-option>
            <el-option label="混合油" value="D"></el-option>
            <el-option label="天然气" value="E"></el-option>
            <el-option label="液化石油气" value="F"></el-option>
            <el-option label="甲醇" value="L"></el-option>
            <el-option label="乙醇" value="M"></el-option>
            <el-option label="太阳能" value="N"></el-option>
            <el-option label="混合动力" value="O"></el-option>
            <el-option label="无" value="Y"></el-option>
            <el-option label="其他" value="Z"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="el_form_item" label="货车类型" style="width: 45%">
             <el-input class="el_inner_width" v-model="form.truck_type" disabled></el-input>
        </el-form-item>
      </div>
      <div class="tab1_inner">
		<el-form-item class="el_form_item" label="车辆类型" style="width: 45%">
            <el-input class="el_inner_width" v-model="form.truck_type1" disabled></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="总质量" style="width: 45%">
            <el-input class="el_inner_width" v-model="form.truck_weight_max" disabled></el-input>
        </el-form-item>
      </div>
      <div class="tab1_inner">
		<el-form-item class="el_form_item" label="是否默认车辆" style="width: 45%">
			 <el-select class="el_inner_width" v-model="form.is_default" disabled>
				<el-option label="是" value="1"></el-option>
				<el-option label="否" value="2"></el-option>
			</el-select>
        </el-form-item>
      </div>

			<div class="big_tit">证件相关</div>
			<div class="tab1_inner">
				<div class="el_form_item" label="" style="width:48%">
					<div class="img_btn">
						<div v-if="!dl_img_src" class="btn">未上传</div>
						<el-image
							v-else
							class="img"
							:src="dl_img_src"
							:previewSrcList="srcList"
							:z-index = 3000
							>
						</el-image>
						<!-- <img  v-else :src="dl_img_src" class="img" @click="show_img(dl_img_src)"> -->
					</div>
					<div class="bottom_text">行驶证
					
					</div>
				</div>
				<div class="el_form_item" style="width:48%;margin-left:1%">
					<div class="img_btn">
						<div v-if="!tl_img_src" class="btn">未上传</div>
						 <el-image
								v-else
								class="img"
								:src="tl_img_src"
								:previewSrcList="tlList"
								:z-index = 3000
								>
							</el-image>
						<!-- <img  v-else :src="tl_img_src" class="img" @click="show_img(tl_img_src)"> -->
					</div>
					<div class="bottom_text">道路运输证
					
					</div>
				</div>
			</div>
		</el-form>
	</el-dialog>
</template>
<script>
	import truck_type_handle from '@/my/_truck_type_handle.js'
	export default {
		props:{
			is_show:Number,//是否显示
			truck_info:Object,//货车详情
		},
		data() {
			return {

				//页面是否显示
				is_show_in_page:false,

				//货车编号
				truck_inner_num:'',
				//表单
				form:{
					truck_plate_num: "", //车牌号
					truck_plate_type: "", //车牌照类型(0:未知,1:黄牌,2:蓝牌,3:绿牌)
					truck_weight: "", //货车额定载重(单位:吨)
					truck_volume: "", //货车额载体积(单位:方)
					en_type: "", //能源类型(A:汽油,B:柴油,C:电,D:混合油,E:天然气,F:液化石油气,L:甲醇,M:乙醇,N:太阳能,O:混合动力,Y:无,Z:其他)
					truck_type: null, //货车类型
					truck_source: "", //车辆来源
					check_faild_reason: "", //审核失败原因
					truck_type1:'',
					truck_vin_code:'', // 车辆识别代号 
					truck_used:'', // 使用性质 
					dl_ca:'', //行驶证发证机关 
					dl_reg_date:'', //行驶证注册日期 
					dl_award_date:'',// 行驶证发证日期 
					tl_code:'', //道路运输证号
					
					tl_cert_code:'', //道路运输证经营许可证号
					truck_weight_max:'', //车辆总重(单位:吨)
					truck_owner_name:'', //所有人姓名 

					manual_ver:'',//五证是否齐全

					is_default:'',//是否默认车辆
				},
				//日期
				time:{
					dl_reg_date:'',
					dl_award_date:'',
				},
				//行驶证
				dl_img_src:'',
				srcList:[],
				//道路运输证
				tl_img_src:'',
				tlList:[],
				
				//大图
				img:{
					is_show:false,
					src:''
				},
				//上传界面
				upl: {
					//是否显示上传界面
					is_show: false,

					//地址
					src: "",

					//备注
					mark: "",
				},
				//车型
				truck_type:[],

				//失败原因列表
				restaurants: [],
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_page=true;
					this.img.is_show=false
				}
			},
			truck_info(new_data){
				if(new_data){
					this.load_data(new_data)
				}
			}
		},
		created() {

			
		},
		mounted() {
		//   this.restaurants = this.loadAll();
		},
		methods: {
			show_img(src){
				this.img.src=src,
				this.img.is_show=true;
			},
			//初始化
			load_data(new_data){
				// 置入数据
				this.truck_inner_num=new_data.truck_inner_num;
				this.truck_id = new_data.id;
				this.form.truck_plate_num = new_data.truck_plate_num;
				this.form.truck_plate_type = new_data.truck_plate_type;
				this.form.truck_weight = new_data.truck_weight;
				this.form.truck_volume = new_data.truck_volume;
				this.form.en_type = new_data.en_type;
				this.form.truck_vin_code =new_data.truck_vin_code, // 车辆识别代号
				this.form.truck_used =new_data.truck_used // 使用性质
				this.form.dl_ca =new_data.dl_ca //行驶证发证机关
				this.form.truck_type1 =new_data.truck_type //车辆类型
				this.form.truck_weight_max =new_data.truck_weight_max //车辆总重(单位:吨)
				this.form.truck_owner_name =new_data.truck_owner_name //所有人姓名
				this.form.manual_ver = new_data.manual_ver // 五证是否齐全
				this.form.is_default=new_data.is_default
                
                this.form.tl_code=new_data.tl_code
                this.form.tl_cert_code=new_data.tl_cert_code
				this.form.truck_type = new_data.truck_type_name+'/'+new_data.truck_type_name2,
				this.form.truck_source = new_data.truck_source;
				this.form.check_faild_reason = new_data.check_faild_reason;
				//日期处理
				if(new_data.dl_reg_date !=0){
					this.time.dl_reg_date =Number(new_data.dl_reg_date) *1000
				}
				if(new_data.dl_award_date !=0){
					this.time.dl_award_date =Number(new_data.dl_award_date) *1000
				}
				this.dl_img_src=this.$my.qiniu.make_src('truckdl',new_data.dl_img)
				
				this.srcList[0]=this.dl_img_src
				this.tl_img_src=this.$my.qiniu.make_src('truckdl',new_data.tl_img)
				this.tlList[0]=this.tl_img_src
				//有道路运输证 且没有道路运输证号
				// if(this.tl_img_src && !new_data.tl_code){
				// 	//道路运输证识别
				// 	this.$my.net.req({
				// 		data:{
							
				// 			mod:'res',
				// 			ctr:'ocr_normal_img',
				// 			car_img:this.tl_img_src
				// 		},callback:(data)=>{
				// 			this.form.tl_code = data.tl_code
				// 			this.form.tl_cert_code = data.tl_cert_code
				// 			//识别不出来默认四个0
				// 			if(data.jy_code){
				// 				this.form.tl_cert_code = data.jy_code
				// 			}else{
				// 				this.form.tl_cert_code ='0000'
				// 			}
							
				// 		}
				// 	})
				// }  else if(!new_data.tl_cert_code){
				// 	this.form.tl_code =new_data.tl_code //道路运输证号
				// 	this.form.tl_cert_code ='0000' //道路运输证经营许可证号
				// }else{
				// 	this.form.tl_code =new_data.tl_code //道路运输证号
				// 	this.form.tl_cert_code = new_data.tl_cert_code
				// }
			},
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
		.img_btn{
			border: 1px solid #bbb;
			border-radius: 5px;
			cursor: pointer;
			padding: 5px;
			height:300px;
			.btn{
				text-align: center;
				line-height: 290px;
				font-size: 14px;
			}

			.img {
			position: relative;
			display: block;
			height: 290px;
			/deep/.el-image__inner {
				margin: auto;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				width: auto !important;
			}
		 }
		}
		.bottom_text{
			margin-top: 5px;
			text-align: center;
		}
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}
	
	.bottom_btns{
		text-align: center;
		margin-top: 30px;
	}

	.form_ordinary{
		// height: calc(100% - 91px);
		padding: 10px 0;
		overflow-y:auto;
	}
	.upl {
		.img_area {
			border: 1px solid #ccc;
			cursor: pointer;
			padding: 5px;
			.show {
			margin: auto;
			display: block;
			max-width: 100%;
			max-height: 100%;
			}
			.icon {
			text-align: center;
			display: block;
			font-size: 100px;
			height: 500px;
			line-height: 500px;
			}
		}
		.mark_area {
			border: 1px solid #ccc;
			padding: 5px;
			width: 448px;
			margin-top: 10px;
			height: 100px;
			outline: none;
			font-size: 16px;
		}
		.mark_area::placeholder {
			font-size: 20px;
			line-height: 100px;
			text-align: center;
		}
		.btn_area {
			text-align: center;
			margin-top: 15px;
		}
	}
</style>